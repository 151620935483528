/* eslint-disable eqeqeq */
import { constants as coreConstants, utils } from '@wsb/guac-widget-core';

const { imageDimensionConfig, FIT_IMAGE, LANDSCAPE, CIRCLE } = coreConstants.imageDimensions;
const transparentPlaceholder = '//img1.wsimg.com/isteam/ip/static/transparent_placeholder.png';

export const LANDSCAPE_ASPECT_RATIO = imageDimensionConfig[LANDSCAPE].aspectRatio;
export const CIRCLE_IMAGE_DIMENSION = CIRCLE;

export const CDN_REGEX = /[.-]wsimg\.com\//;
const { hasOwnProperty } = Object.prototype;

export function isInternalUrl(url) {
  return !!CDN_REGEX.test(url);
}

function parseOperationsUrl(operationsUrl) {
  if (!operationsUrl) {
    return [];
  }
  return operationsUrl
    .split('/')
    .filter(Boolean)
    .map(operation => {
      const [name, value] = operation.split('=');
      return { name, value };
    });
}

function parseImageApiUrl(url) {
  if (!isInternalUrl(url)) {
    return null;
  }
  const [source, operations] = url.split('/:/');
  return { source, operations: parseOperationsUrl(operations) };
}

function stringifyParsedImageApiUrl({ source, operations }) {
  if (operations == null) {
    return source;
  }
  const operationsUrl = operations
    .map(({ name, value }) => (value == null ? name : `${name}=${value}`))
    .join('/');
  if (!operationsUrl) {
    return source;
  }
  return source + '/:/' + operationsUrl;
}

function toOperationsArray(operations) {
  if (operations == null) {
    return [];
  }
  if (typeof operations === 'string') {
    return parseOperationsUrl(operations);
  }
  if (Array.isArray(operations)) {
    return operations;
  }
  return Object.entries(operations).map(([name, value]) => ({ name, value }));
}

export function addImageApiArgs(url, operations) {
  const parsedUrl = parseImageApiUrl(url);
  if (parsedUrl == null) {
    return url;
  }
  return stringifyParsedImageApiUrl({
    source: parsedUrl.source,
    operations: [...parsedUrl.operations, ...toOperationsArray(operations)]
  });
}

export function replaceImageApiArgs(url, operations) {
  const parsedUrl = parseImageApiUrl(url);
  if (parsedUrl == null) {
    return url;
  }
  // if the operation doesn't already exists, it's ignored
  parsedUrl.operations.forEach(operation => {
    const { name } = operation;
    if (hasOwnProperty.call(operations, name)) {
      // Replacing value by mutating the object
      operation.value = operations[name];
    }
  });
  return stringifyParsedImageApiUrl(parsedUrl);
}

// Please avoid this method if you can and use replaceImageApiArgs instead.
export function replaceImageSizeParams(url, replacement = '') {
  if (!url || typeof url !== 'string') {
    return '';
  }
  return url.replace(/\/:\/rs=w:[0-9]*,h:[0-9]*/, replacement);
}

function stripParams(url = '') {
  return url.split('/:/')[0];
}

export function getValidUrl(url) {
  if (!isInternalUrl(url)) {
    return stripParams(url);
  }
  return url;
}

export function generateBackgroundUrl(background, includeDynamicResize = true) {
  const url = includeDynamicResize
    ? utils.generateBackgroundUrl(background)
    : utils
      .generateBackgroundUrl(background)
      .replace(/\/rs=w:{width},h:{height},cg:true,m\/cr=w:{width},h:{height},a[x]?:[^/]*/, '')
      .replace(/\/:$/, '');

  return getValidUrl(url);
}

export function resolveImageSource(imageData, legacySrc, fallback) {
  const url = imageData?.imageUrl || imageData?.image;
  if (typeof url === 'string') {
    return getValidUrl(utils.generateImageServiceUrl(imageData));
  }

  if (fallback?.fallbackBackgroundImageSrc) {
    return fallback.fallbackBackgroundImageSrc.replace(/\{(width|height)\}/g, '+0');
  }

  return legacySrc || '';
}

/* eslint-disable max-len */
export const lazyLoadImagesScriptMinified = `const imageObserver=new IntersectionObserver((e,r)=>{var a=e=>{if(e.hasAttribute("data-lazyimg")){var t=e.getAttribute("data-srclazy");let o=e.getAttribute("data-srcsetlazy")||"";if(t&&(e.src=t),o&&window.networkInfo){var n=window.networkInfo.downlink;const r=[{min:0,max:1,regex:/(.*?(?=, ))/,qMod:!0},{min:1,max:5,regex:/(.*2x)/}];r.forEach(({min:e,max:t,regex:r,qMod:a})=>{e<=n&&n<t&&(o=(o.match(r)[0]||o)+(a?"/qt=q:"+Math.round(100*n):""))})}e.srcset=o,e.removeAttribute("sizes"),e.removeAttribute("data-lazyimg"),e.removeAttribute("data-srclazy"),e.removeAttribute("data-srcsetlazy")}};e.forEach(e=>{if(e.isIntersecting){const t=e.target;window.networkInfo&&0===window.networkInfo.downlink||([t].concat(Array.from(t.querySelectorAll("[data-lazyimg]"))).forEach(a),r.unobserve(t))}})},{rootMargin:"150px"});document.addEventListener("DOMContentLoaded",function(){document.querySelectorAll("[data-lazyimg]").forEach(e=>imageObserver.observe(e))});`;
/* lazy load images script:
const imageObserver = new IntersectionObserver((entries, imgObserver) => {
  const loadImage = node => {
    if (!node.hasAttribute('data-lazyimg')) return;
    const src = node.getAttribute('data-srclazy');
    let srcSet = node.getAttribute('data-srcsetlazy') || '';
    if (src) {
      node.src = src;
    }
    if (srcSet && window.networkInfo) {
      const downlink = window.networkInfo.downlink;
      const thresholds = [{ min: 0, max: 1, regex: /(.*?(?=, ))/, qMod: true }, { min: 1, max: 5, regex: /(.*2x)/ }];
      thresholds.forEach(({ min, max, regex, qMod }) => {
        if (downlink >= min && downlink < max) {
          srcSet = (srcSet.match(regex)[0] || srcSet) + (qMod ? '/qt=q:' + Math.round(downlink * 100) : '');
        }
      });
    }
    node.srcset = srcSet;
    node.removeAttribute('sizes');
    node.removeAttribute('data-lazyimg');
    node.removeAttribute('data-srclazy');
    node.removeAttribute('data-srcsetlazy');
  };
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      const node = entry.target;
      if (window.networkInfo && window.networkInfo.downlink === 0) {
        return;
      }
      [node].concat(Array.from(node.querySelectorAll('[data-lazyimg]'))).forEach(loadImage);
      imgObserver.unobserve(node);
    }
  })
}, { rootMargin: '150px' });
document.addEventListener('DOMContentLoaded', function() {
  document.querySelectorAll('[data-lazyimg]').forEach(node => imageObserver.observe(node));
});
*/

export function getImageDimensionData(imageData = {}) {
  const { outputWidth, outputHeight, imageDimension, enableImageDimension } = imageData;
  const imageDimensionStyles = {};
  const parsedImageData = { ...imageData };

  if (imageDimension) {
    if (imageDimension === FIT_IMAGE) {
      delete parsedImageData.outputHeight;
      delete parsedImageData.editedAspectRatio;
      imageDimensionStyles.borderRadius = 0;
    } else {
      const { aspectRatio, borderRadius } = imageDimensionConfig[imageDimension] || {};
      if (aspectRatio && outputWidth) {
        parsedImageData.outputHeight = outputWidth / aspectRatio;
      } else if (aspectRatio && outputHeight) {
        parsedImageData.outputWidth = outputHeight * aspectRatio;
      }
      imageDimensionStyles.borderRadius = borderRadius;
    }
  }

  return {
    imageDimensionStyles,
    parsedImageData,
    enableImageDimension
  };
}

// oHeight and oWidth values need to be shifted if an image is rotated
// rotation would affect aspect ratio based calculations
export function getOriginalImageDimensions({ oWidth, oHeight, rotation }) {
  const parsedRotation = parseInt(rotation || 0, 10);
  const hasShiftedDimensions = parsedRotation % 180 > 0;
  return {
    height: hasShiftedDimensions ? oWidth : oHeight,
    width: hasShiftedDimensions ? oHeight : oWidth
  };
}

export function hasNegativeCrop({ top, left } = {}) {
  const parsedTop = parseInt(top || 0, 10);
  const parsedLeft = parseInt(left || 0, 10);
  return parsedTop < 0 || parsedLeft < 0;
}

export function getInferredDimensions(imageData = {}) {
  const { outputHeight, outputWidth, editedAspectRatio } = imageData;
  const { height, width } = getOriginalImageDimensions(imageData);
  // set aspectRatio to null since all images on mobile need to be full width for current logic to work correctly
  // outputWidth, outputHeight, oWidth, oHeight are not accurate predictors of rendered aspect ratio if crop values are negative
  const aspectRatio = hasNegativeCrop(imageData)
    ? null
    : editedAspectRatio ||
      (outputWidth && outputHeight && outputWidth / outputHeight) ||
      width / height;
  if (isNaN(aspectRatio) || !isFinite(aspectRatio)) return {};
  const inferredHeight = outputHeight || outputWidth / aspectRatio;
  const inferredWidth = outputWidth || outputHeight * aspectRatio;
  return {
    outputHeight: inferredHeight && inferredWidth ? inferredHeight : height,
    outputWidth: inferredHeight && inferredWidth ? inferredWidth : width,
    aspectRatio
  };
}

function generateClampedSource(inferredImageDimensions, maxSize) {
  const { outputHeight, outputWidth, aspectRatio } = inferredImageDimensions;
  let scaledWidth = Math.max(0, Math.min(outputWidth, maxSize));
  const widthRatio = outputWidth / scaledWidth;
  let scaledHeight = Math.floor(outputHeight / widthRatio);
  if (!outputWidth && aspectRatio) {
    scaledWidth = maxSize;
    scaledHeight = scaledWidth / aspectRatio;
  }
  let args;
  if (scaledWidth) {
    args = `w:${scaledWidth}`;
    if (scaledHeight) {
      args += `,h:${scaledHeight}`;
    }
    args += ',cg:true,m,i:true';
  }
  return addImageApiArgs(transparentPlaceholder, { rs: args, qt: 'q:1' });
}

export function createClampedSources(inferredImageDimensions, sizes) {
  return sizes.map(size => {
    return `${generateClampedSource(inferredImageDimensions, size)} ${size}w`;
  });
}
